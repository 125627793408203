@charset "utf-8";
.l-footer{
    color: #fff;

    img {
        width: 100%;
        height: auto;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }


    &__wrap{
      @include sp {
        display: none;
      }
    }
    &__sitemap {
        position: relative;
        padding: 65px 0 80px 0;
        background-color: #053306;
    }
    &__sitemapContent{
        width: 1030px;
        margin: 0 auto;
    }
    &__sitemapHeading {
        width: 90px;
    }

    &__sitemapList {
        display: flex;
    }

    &__sitemapListItem {
        &:not(:first-child) {
            margin-left: 40px;
        }

        ul {
            margin-top: 15px;
            display: none;

            li {
                font-size: 14px;

                &:not(:first-child) {
                    margin-top: 10px;
                }

                a {
                    color: #fff;
                }
            }
        }
    }

    &__sitemapTtlText {
        padding-bottom: 5px;
        font-size: 18px;
        letter-spacing: 0.05em;
        border-bottom: 1px solid #fff;
        width: 228px;
        a{
          color: #fff;
        }
    }

    &__sitemapTtl {
      position: relative;
    }

    &__sitemapTxt{
      position: relative;
      padding-right: 25px;
      padding-bottom: 10px;
      font-size: 12px;
      border-bottom: 1px solid #415041;
    }


    &__accordion2{
        margin-left: 20px;
        display: none;
    }

    &__accordionBtn1 {
      position: absolute;
      right: 0;
      top: 12px;
      width: 16px;
    }

    &__accordionBtn2 {
      position: absolute;
      right: 0;
      top: 12px;
      width: 16px;
    }

    &__accordionBtnIcon {
      cursor: pointer;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      right: 0;
      background: url(#{$imgPath}common/plus_icon.png) no-repeat center center;
      transform: translateY(-50%);

      &.is-active {
          background: url(#{$imgPath}common/minus_icon.png) no-repeat center center;
      }
    }

    &__bottomBlock {
        display: flex;
        align-items: center;
        height: 50px;
        background-color: #021C03;
        @include sp {
            display: block;
            height: auto;
            padding: 70/750*100vw 25/750*100vw 65/750*100vw;
        }
    }

    &__toTop{
      position: fixed;
      right: 40px;
      bottom: 87px;
      z-index: 1000;
      @include sp {
        right: 30/750*100vw;
        bottom: 64/750*100vw;
      }
      img{
        width: 80px;
        @include sp {
          width: 140/750*100vw;
        }
      }
    }

    &__bottomBlockWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 1020px;
        margin: 0 auto;
        @include sp {
            display: block;
            max-width: 100%;
        }
    }

    &__contactList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sp {
            flex-wrap: wrap;
        }
    }

    &__contactListItem {
      padding: 0 30px;
      font-size: 14px;
      @include sp{
          width: 48%;
          @include vw(26);
          padding: 0 0 16/750*100vw;
          margin-bottom: 35/750*100vw;
          border-bottom: 1px solid #afafaf;
      }
      @include pc{
          &:first-child {
              padding-left: 0;
          }
          &:last-child {
              padding-right: 0;
          }
          &:not(:first-child) {
            position: relative;
            &::before{
              position: absolute;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              height: 10px;
              width: 1px;
              background-color: #707070;
            }

          }

      }
      a{
        color: #fff;
      }

  }

    &__share {
        display: flex;
        padding: 0;
        margin: -3px 30px 0 auto ;
        @include sp {
            margin: -25/750*100vw 0 0 0 ;
        }
    }

    &__shareItem {
        width: 17px;
        margin-left: 18px;

        @include sp {
            width: 52/750*100vw;
            margin-left: auto;
        }
    }

    &__logo{
      height: 78px;
      width: 1027px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      @include sp {
        height: 268/750*100vw;
        width: 100%;
        display: block;
        padding:64/750*100vw 0 0 30/750*100vw;
      }
    }

    &__logoImg{
      @include sp {
        margin-bottom:29/750*100vw;
      }
      img{
        width: 113px;
        @include sp {
          width: 214/750*100vw;
        }
      }

    }

    &__copyright{
      color: #000;
      font-size: 11px;
      @include sp {
        @include vw(20);
      }
    }
}
