@charset "utf-8";

.p-infoNews {
  ul{
    list-style: none;
  }
  margin-bottom: 120px;
  @include sp{
    margin-bottom: 250/750*100vw;
  }

  &__tab{
    margin-bottom: 80px;
    position: relative;
    @include sp{
      margin-bottom: 80/750*100vw;
    }
    &::after{
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      background: #9f9f9f;
    }
  }

  &__panel{
    border-top: solid 1px #9f9f9f;
  }


  // ページネーション
  &__pagination {
    // position: absolute;
    // right: 0;
    margin-top: 22px;
    @include sp{
      margin-top: 62/750*100vw;
      // right: 50%;
      // transform: translateX(50%);
    }
  }

  &__paginationWrap{
    display: flex;
    align-items: center;
  }
  .page-numbers{
    display: flex;
    justify-content: flex-end;
    li{
      margin-left: 10px;
      @include sp{
        margin-left: 15/750*100vw;
      }
    }
  }
  &__page a {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    color: #7C7C7C;
    border: solid 1px #7C7C7C;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp{
      width: 68/750*100vw;
      height: 68/750*100vw;
    }

    &:hover{
      border: solid 1px #0d750f;
      color: #0d750f;
    }
  }
  a.page-numbers,span.page-numbers{
    width: 30px;
    height: 30px;
    color: #7C7C7C;
    border: solid 1px #7C7C7C;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp{
      width: 68/750*100vw;
      height: 68/750*100vw;
    }
    &:hover{
      border: solid 1px #0d750f;
      color: #0d750f;
    }
    &.current{
      border: solid 1px #0d750f;
      color: #0d750f;
    }
    &.prev{
      font-size: 15px;
      margin-right: 0;
      border: none;
      @include sp{
        margin-right: 30/750*100vw;
        @include vw(33);
      }
    }
    &.next{
      font-size: 15px;
      border: none;
      @include sp{
        margin-left: 50/750*100vw;
        @include vw(33);
      }
    }
    &.dots{
      border: none;
    }
  }
  &__prev{
    font-size: 15px;
    margin-right: 0;
    border: none;
    @include sp{
      margin-right: 30/750*100vw;
      @include vw(33);
    }
  }

  &__next{
    font-size: 15px;
    margin-left: 10px;
    @include sp{
      margin-left: 50/750*100vw;
      @include vw(33);
    }
  }


}
