@charset "utf-8";

//下層ページ
.m-kv {
  background: url('../img/common/h1_bg.jpg') no-repeat center bottom;
  width: 1206px;
  height: 340px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @include sp{
    background: url('../img/common/h1_bg_sp.jpg') no-repeat center bottom;
    background-size: contain;
    width: 690/750*100vw;
    height: 340/750*100vw;
  }

  &__inner{
    margin-left: 90px;
    @include sp{
      margin-left: 40/750*100vw;
    }
  }

  &__sub{
    font-size: 20px;
    color: #fff;
    font-weight: 900;
    @include sp{
      @include vw(26);
    }
  }

  &__txt{
    color: #fff;
    font-size: 50px;
    line-height: 1.1;
    @include sp{
      @include vw(52);
    }
  }

}
