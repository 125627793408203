@charset "utf-8";

//タブ切り替え
.m-tab {
  position: relative;
  width: 1028px;
  margin: 80px auto 60px;
  @include sp{
    width: 690/750*100vw;
    margin: 120/750*100vw auto 80/750*100vw;
  }

  &__sub{
    color: #0D750F;
    font-size: 16px;
    font-weight: bold;
    @include sp{
      @include vw(26);
    }
  }

  &__ttl{
    font-size: 32px;
    font-weight: bold;
    @include sp{
      @include vw(52);
    }
  }


  &__tab{
    width: 100%;
    display: flex;
    margin: 25px auto 0;
    @include sp{
      margin:42/750*100vw auto 0;
    }
  }

  &__tabWrap{
    text-align: center;
    cursor: pointer;
    border: solid 1px #D1D1D1;
    width: 342/1028*100%;
    @include sp{
      width: 100%;
    }
  }

  &__tabWrap.active{
      background:#0D750F;
      color: #fff;
      z-index: 10;
  }

  &__panel{
    display:none;
  }

  &__panel.active{
    display:block;
    position: relative;
  }

  &__tabMenu{
    font-size: 16px;
    font-weight: bold;
    line-height: 56px;
    height: 56px;
    @include sp{
      @include vw(26);
      height: 80/750*100vw;
      line-height: 80/750*100vw;
    }

  }

  &__content{
    text-align: center;
    border-bottom: solid 1px #9F9F9F;
    @include sp{
      text-align: left;
    }
  }

  &__list{
    padding: 0;
    margin: 0;
  }


  &__inner{
    display: flex;
    align-items: center;
    padding: 23px 0 23px 24px;
    @include sp{
      padding: 40/750*100vw 30/750*100vw ;
      display: block;
    }
    &:nth-child(odd){
      background-color: #F8F8F8;
    }
  }

  &__top{
    display: flex;
    align-items: center;
    @include sp{
      margin-bottom: 20/750*100vw ;
    }
  }

  &__date{
    font-size: 14px;
    @include sp{
      @include vw(26);
    }
  }


  &__txt{
    font-size: 16px;
    padding-left: 24px;
    position: relative;
    @include sp{
      margin-top: 23/750*100vw;
      padding-left: 0;
      @include vw(28);
    }
    @include pc{
      &::before{
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 40px;
        background: #DEDEDE;
      }

    }
  }


  &__tag{
    list-style: none;
    display: flex;
    padding: 0 24px;
    @include sp{
      padding: 0 31/750*100vw;
    }

  }

  &__tagTxt{
    margin-right: 5px;
    height: 2.2em;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    @include sp{
      width: 5.5em;
      @include vw(28);
      margin-right: 5px;
    }
    &--01{
      color: #D90300;
      border: 1px solid #D90300;
    }
    &--02{
      color: #0D750F;
      border: 1px solid #0D750F;
    }
  }


}

