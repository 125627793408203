@charset "utf-8";

.p-topClimbing {
  background-color: #F8F8F8;

  &__box{
    width: 1366px;
    margin: 0 auto;
    padding-top: 120px;
    @include sp {
      width: 690/750*100vw;
      padding: 100/750*100vw 0 120/750*100vw;
    }
  }
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 80px;
    @include sp {
      display: block;
      padding: 0;
    }
  }

  &__headingBanner {
    width: 494px;
    @include sp {
      width: 690/750*100vw;
      margin-top: 25/750*100vw;
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    height: 688px;
    background: url(#{$imgPath}top/climbing/bg_img.jpg) no-repeat right bottom;
    z-index: 1;
    @include sp {
      height: auto;
      margin-top: 10/750*100vw;
      background: none;
    }
  }

  &__content {
    &::before {
      content: '';
      position: absolute;
      right: 80px;
      width: 583px;
      height: 390px;
      background: url(#{$imgPath}top/climbing/img.jpg) no-repeat center center/cover;
      z-index: 2;
      @include sp {
        display: block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 690/750*100vw;
        height: 460/750*100vw;
      }
    }
  }

  &__inner {
    position: absolute;
    left: 0;
    margin-top: 50px;
    margin-left: 80px;
    width: 900px;
    padding: 100px 0 120px 123px;
    background-color: #fff;
    z-index: 1;
    @include sp {
      position: relative;
      width: 690/750*100vw;
      margin: 0;
      padding: 50/750*100vw 70/750*100vw 80/750*100vw;
    }
  }

  &__ttl {
    font-size: 32px;
    font-weight: 900;
    @include sp {
      @include vw(40);
    }
  }

  &__lead {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #555555;
    @include sp {
      margin-top: 10/750*100vw;
      @include vw(28);
    }
  }

  &__link {
    margin-top: 40px;
    @include sp {
      @include vw(28);
      margin-top: 40/750*100vw;
      line-height: 1.42857143;
    }
  }

  &__contentBanner {
    width: 400px;
    margin-top: 40px;
    @include sp {
      width: 100%;
      margin-top: 40/750*100vw;
    }
  }
}
