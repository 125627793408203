@charset "utf-8";

//お知らせ詳細
.p-detail {
  width: 1028px;
  margin: 53px auto 100px;
  @include sp{
    width: 690/750*100vw;
    margin: 80/750*100vw auto 114/750*100vw;;
  }

  &__top{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @include sp{
      margin-bottom: 40/750*100vw;;
    }
    img{
      margin-right: 8px;
      @include sp{
        width: 160/750*100vw;
        margin-right: 10/750*100vw;
      }
    }
  }

  &__date{
    margin-left: 12px;
    font-size: 14px;
    flex: 1;
    @include sp{
      margin-left: 16/750*100vw;
      @include vw(26);
    }
  }

  &__ttl{
    position: relative;
    color: #0D750F;
    font-size: 32px;
    border-bottom: solid 3px #0D750F;
    padding: 0 0 17px 29px;
    margin-bottom: 40px;
    @include sp{
      @include vw(42);
      padding: 0 0 25/750*100vw 40/750*100vw;
      margin-bottom: 40/750*100vw;
    }
    //　赤丸
    &::before{
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: #D90300;
      border-radius: 50%;
      left: 0;
      top: 20px;
      @include sp{
        top: 25/750*100vw;
        left: 7/750*100vw;
        width: 20/750*100vw;
        height: 20/750*100vw;
      }
    }
  }


  &__sub{
    position: relative;
    font-size: 20px;
    padding-left: 33px;
    line-height: 52px;
    margin-bottom: 24px;
    @include sp{
      @include vw(32);
      padding-left: 40/750*100vw;
      margin-bottom: 40/750*100vw;
      line-height: 80/750*100vw;
    }
    // 緑線
    &::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 52px;
      background-color: #0D750F;
      left: 0;
      @include sp{
        width: 10/750*100vw;
        height: 80/750*100vw;
      }
    }
  }


  //本文
  &__txt{
    font-size: 16px;
    line-height: 2.0;
    margin-bottom: 80px;
    @include sp{
      @include vw(28);
      margin-bottom: 121/750*100vw;
    }
  }

  //表
  &__table{
    @include sp{
      overflow-x: scroll;
      margin-bottom: 120/750*100vw;
    }
  }
  &__tableWrap{
    width: 1028px;
    margin-bottom: 81px;
    @include sp{
      width: 1651/750*100vw;
      margin-bottom: 20/750*100vw;
      overflow-x: scroll;
    }
    th{
      border: solid 1px #7C7C7C;
      width: 178px;
      height: 60px;
      background: #EBEBEB;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 400;
      @include sp{
        width: 286/750*100vw;
        height: 96/750*100vw;
        @include vw(26);
      }
    }
    td{
      border: solid 1px #7C7C7C;
      height: 60px;
      vertical-align: middle;
      padding-left: 18px;
      font-size: 15px;
      @include sp{
        height: 96/750*100vw;
        padding-left: 31/750*100vw;
        @include vw(26);
      }
    }
  }

  &__btn{
    border: solid 1px #9F9F9F;
    width: 396px;
    height: 78px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    @include sp{
      width: 452/750*100vw;
      height: 100/750*100vw;
      @include vw(26);
    }

  }

  &__page{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 336px;
    margin: 40px auto 0;
    @include sp{
      width: 452/750*100vw;
      margin: 100/750*100vw auto 0;
    }
  }

  &__prev{
    font-size: 18px;
    font-weight: bold;
    width: 130px;
    text-align: right;
    position: relative;
    @include sp{
      width: 180/750*100vw;
      @include vw(26);
    }
    // &::before{
    //   position: absolute;
    //   content: '＜';
    //   width: 18px;
    //   height: 100%;
    //   left: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   @include sp{
    //     width: 26/750*100vw;
    //   }
    // }
  }

  &__center{
    border-right: solid 1px #707070;
    height: 22px;
    @include sp{
      height: 28/750*100vw;
    }
  }

  &__next{
    font-size: 18px;
    font-weight: bold;
    width: 130px;
    position: relative;
    @include sp{
      width: 180/750*100vw;
      @include vw(26);
    }
    // &::before{
    //   position: absolute;
    //   content: '＞';
    //   width: 18px;
    //   height: 100%;
    //   right: 0;
    //   @include sp{
    //     width: 26/750*100vw;
    //   }
    // }

  }

}
.p-detailTags{
  list-style: none;
  display: flex;
  &__item{
    margin-right: 5px;
    height: 2.2em;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    @include sp{
      width: 5.5em;
      @include vw(28);
      margin-right: 5px;
    }
    &--red{
      color: #D90300;
      border: 1px solid #D90300;
    }
    &--green{
      color: #0D750F;
      border: 1px solid #0D750F;
    }
  }
}
