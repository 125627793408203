@charset "utf-8";

.p-categorySitemap{

  ul{
    list-style: none;
    padding-left: 0;
  }

  background: #F8F8F8;
  position: relative;
  &::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 200px;
    background: #F8F8F8;
    top: -200px;
    right: 0;
    z-index: -1;
    @include sp{
      height: 170/750*100vw;
      top: -170/750*100vw;
    }
  }

  &__content{
    width: 1028px;
    margin: 0 auto;
    padding: 50px 0 78px;
    @include sp{
      width: 100%;
      padding: 80/750*100vw 0 80/750*100vw;
    }
  }

  &__inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp{
      display: block;
    }
  }

  &__wrap{
    width: 100%;
    height: 128px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 41px;
    border-bottom: solid 5px #0D750F;
    background: #fff;
    font-size: 16px;
    font-weight: bold;
    @include sp{
      height: 200/750*100vw;
      padding: 0 40/750*100vw;
      @include vw(26);
    }

  }

  &__txt{
    width: 506px;
    margin-bottom: 42px;
    @include sp{
      width: 690/750*100vw;
      margin: 0 auto 64/750*100vw;
    }
  }

  &__accordion{
    display: none;
    padding-top: 40px;
    @include sp{
      padding-top: 64/750*100vw;
    }
  }

  &__list{
    margin-bottom: 23px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: underline;
    @include sp{
      margin-bottom: 37/750*100vw;
      @include vw(26);
    }
  }


  &__accordionBtnIcon{
    cursor: pointer;
    width: 28px;
    height: 28px;
    background: url(#{$imgPath}common/common_plus.png) no-repeat center center;
    background-size: contain;
    @include sp{
      width: 50/750*100vw;
      height: 50/750*100vw;
    }
    img{
      width: 28px;
      @include sp{
        width: 100%;
      }

    }
    &.is-active {
      background: url(#{$imgPath}common/common_minus.png) no-repeat center center;
      background-size: contain;
    }
  }

}
