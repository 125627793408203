@charset "utf-8";

.gsc-control-wrapper-cse{
  width: 1028px!important;
  margin: 0 auto;
  @include sp{
    width: 690/750*100vw!important;
  }
}


.gsc-selected-option-container{
  min-width: 150px!important;
}

.gsc-inline-block{
  vertical-align: baseline!important;
}
