@charset "utf-8";

// ページ下部　リンク
.m-read {

  ul{
    list-style: none;
    padding: 0;
  }

  padding: 100px 0 60px;
  background: #F8F8F8;
  @include sp{
    padding: 100/750*100vw 0 120/750*100vw;
  }
  &__inner{
    width: 1028px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include sp{
      width: 690/750*100vw;
      display: block;
    }
  }

  &__item{
    &:not(:first-child){
      @include sp{
        margin-top: 80/750*100vw;
      }
    }

  }

  &__itemInner{
    display: block;
    width: 494px;
    display: flex;
    justify-content: space-between;
    @include sp{
      width: 100%;
    }
    img{
      width: 227px;
      @include sp{
        width: 333/750*100vw;
      }
    }
  }

  &__txtWrap{
    position: relative;
    border-bottom: solid 1px #9F9F9F;
    width: 248px;
    @include sp{
      width: 333/750*100vw;
    }
  }

  &__txt{
    font-size: 18px;
    font-weight: bold;
    @include sp{
      @include vw(26)
    }
  }

  &__btm{
    position: absolute;
    bottom: 0;
  }


  ul, li{
    list-style: none;
  }

}
