@charset "utf-8";

.m-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sp {
    display: block;
    text-align: center;
    font-weight: bold;
    img {
      width: auto;
      height: 100%;
    }
  }

  //VIEW ALL
  &--view {
    width: 130px;
    margin: 15px 0 0 auto;
    position: relative;
    @include sp {
      width: 560/750*100vw;
      height: 100/750*100vw;
      background-color: #D90300;
      margin: 80/750*100vw auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        display: flex;
      }
    }
    &::after{
      position: absolute;
      content: url(../img/common/link_arrow.png);
      width: 32px;
      height: 5px;
      top: 0;
      right: 0;
      @include sp{
        display: none;
      }
    }

    img{
      @include sp {
        width: 150/750*100vw;
      }
    }
  }

  // 山岳TOP　スポーツクライミングTOP
  &--wide {
    width: 400px;
    padding-bottom: 15px;
    border-bottom: 1px solid #F4F2ED;
    position: relative;
    @include sp {
      width: 100%;
      padding: 30/750*100vw 24/750*100vw;
      border: 1px solid #E8E8E8;
    }
    &::after{
      position: absolute;
      content: '';
      background:  url(../img/common/link_arrow.png) no-repeat center;
      background-size: contain;
      width: 32px;
      height: 100%;
      top: 40%;
      right: 0;
      transform: translateY(-50%);
      @include sp{
        width: 51/750*100vw;
        top: 50%;
        right: 20/750*100vw;
      }
    }
  }

  // READ MORE
  &--more{
    margin-bottom: 16px;
    display: inline-block;
    position: relative;
    width: 107px;
    img{
      width: 100%!important;
    }
    @include sp{
      margin-bottom: 22/750*100vw;
      width: 155/750*100vw;
    }
    &::after{
      position: absolute;
      content: '';
      background:  url(../img/common/link_arrow.png) no-repeat center;
      background-size: contain;
      width: 32px;
      height: 100%;
      top: 70%;
      left: 110%;
      transform: translateY(-50%);
      @include sp{
        width: 51/750*100vw;
      }
    }
  }

  &__text {

    // 山岳TOP　スポーツクライミングTOP
    &--wide {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 900;
      @include sp {
        @include vw(26);
      }

      // 赤丸
      &::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        border-radius: 10px;
        background-color: #D90300;
        @include sp {
          width: 12/750*100vw;
          height: 12/750*100vw;
          margin-right: 10/750*100vw;
        }
      }
    }
  }

  // 赤矢印
  &__arrow {
    width: 32px;
    @include sp {
      width: 51/750*100vw;
      img {
        width: 100%;
        height: auto;
        @include sp {
          vertical-align: middle;
        }
      }
    }
  }
}
