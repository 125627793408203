@charset "utf-8";

.p-topFacebook {
  position: relative;
  @include sp{
    padding: 110/750*100vw 0 100/750*100vw;
  }
  &::before{
    content: '';
    display: inline-block;
    background: #0D750F;
    width: 42%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @include sp{
      width: 327/750*100vw;
      height: 100%;
    }
  }
  &__wrap{
    width: 939px;
    margin: 0 auto;
    @include sp{
      width: 690/750*100vw;
    }
  }
  &__flex{
    display: flex;
    padding: 80px 0;
    @include sp{
      flex-wrap: wrap;
      padding:0;
    }
  }
  &__logo{
    margin-right: 80px;
    @include sp{
      width: 184/750*100vw;
      margin: 0;
    }
  }
  &__facebook{
    @include sp{
      width: 100%;
      margin-top: 20/750*100vw;
    }
  }

  &__inner{
    width: 500px;
    @include sp{
      width: 100%;
      margin: 0 auto;
    }
  }

}
