@charset "utf-8";
.u{
  //margin
    &-m0{
        margin: 0;
    }

    //margin-top
    &-mt0{
      margin-top: 0;
    }

    &-mt10{
        margin-top: 10px;
        @include sp{
            margin-top: 10/750*100vw
        }
    }

    &-mt20{
        margin-top: 20px;
        @include sp{
            margin-top: 20/750*100vw
        }
    }

    &-mt30{
        margin-top: 30px;
        @include sp{
            margin-top: 30/750*100vw
        }
    }

    &-mt40{
        margin-top: 40px;
        @include sp{
            margin-top: 40/750*100vw
        }
    }

    &-mt50{
        margin-top: 50px;
        @include sp{
            margin-top: 50/750*100vw
        }
    }

    //margin-bottom
    &-mb0{
      margin-bottom: 0;
    }

    &-mb10{
        margin-bottom: 10px;
        @include sp{
            margin-bottom: 10/750*100vw
        }
    }

    &-mb20{
        margin-bottom: 20px;
        @include sp{
            margin-bottom: 20/750*100vw
        }
    }

    &-mb30{
        margin-bottom: 30px;
        @include sp{
            margin-bottom: 30/750*100vw
        }
    }

    &-mb40{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 40/750*100vw
        }
    }

    &-mb50{
        margin-bottom: 50px;
        @include sp{
            margin-bottom: 50/750*100vw
        }
    }

    //margin-left
    &-ml0{
      margin-left: 0;
    }

    &-ml10{
        margin-left: 10px;
        @include sp{
            margin-left: 10/750*100vw
        }
    }

    &-ml20{
        margin-left: 20px;
        @include sp{
            margin-left: 20/750*100vw
        }
    }

    &-ml30{
        margin-left: 30px;
        @include sp{
            margin-left: 30/750*100vw
        }
    }

    &-ml40{
        margin-left: 40px;
        @include sp{
            margin-left: 40/750*100vw
        }
    }

    &-ml50{
        margin-left: 50px;
        @include sp{
            margin-left: 50/750*100vw
        }
    }

    //margin-right
    &-mr0{
      margin-right: 0;
    }

    &-mr10{
        margin-right: 10px;
        @include sp{
            margin-right: 10/750*100vw
        }
    }

    &-mr20{
        margin-right: 20px;
        @include sp{
            margin-right: 20/750*100vw
        }
    }

    &-mr30{
        margin-right: 30px;
        @include sp{
            margin-right: 30/750*100vw
        }
    }

    &-mr40{
        margin-right: 40px;
        @include sp{
            margin-right: 40/750*100vw
        }
    }

    &-mr50{
        margin-right: 50px;
        @include sp{
            margin-right: 50/750*100vw
        }
    }

    // padding
    &-p0{
      padding: 0;
    }

    //padding-top
    &-pt0{
      padding-top: 0;
    }

    &-pt10{
        padding-top: 10px;
        @include sp{
            padding-top: 10/750*100vw
        }
    }

    &-pt20{
        padding-top: 20px;
        @include sp{
            padding-top: 20/750*100vw
        }
    }

    &-pt30{
        padding-top: 30px;
        @include sp{
            padding-top: 30/750*100vw
        }
    }

    &-pt40{
        padding-top: 40px;
        @include sp{
            padding-top: 40/750*100vw
        }
    }

    &-pt50{
        padding-top: 50px;
        @include sp{
            padding-top: 50/750*100vw
        }
    }

    //padding-bottom
    &-pb0{
      padding-bottom: 0;
    }

    &-pb10{
        padding-bottom: 10px;
        @include sp{
            padding-bottom: 10/750*100vw
        }
    }

    &-pb20{
        padding-bottom: 20px;
        @include sp{
            padding-bottom: 20/750*100vw
        }
    }

    &-pb30{
        padding-bottom: 30px;
        @include sp{
            padding-bottom: 30/750*100vw
        }
    }

    &-pb40{
        padding-bottom: 40px;
        @include sp{
            padding-bottom: 40/750*100vw
        }
    }

    &-pb50{
        padding-bottom: 50px;
        @include sp{
            padding-bottom: 50/750*100vw
        }
    }

    //padding-left
    &-pl0{
      padding-left: 0;
    }

    &-pl10{
        padding-left: 10px;
        @include sp{
            padding-left: 10/750*100vw
        }
    }

    &-pl20{
        padding-left: 20px;
        @include sp{
            padding-left: 20/750*100vw
        }
    }

    &-pl30{
        padding-left: 30px;
        @include sp{
            padding-left: 30/750*100vw
        }
    }

    &-pl40{
        padding-left: 40px;
        @include sp{
            padding-left: 40/750*100vw
        }
    }

    &-pl50{
        padding-left: 50px;
        @include sp{
            padding-left: 50/750*100vw
        }
    }

    //padding-right
    &-pr0{
      padding-right: 0;
    }

    &-pr10{
        padding-right: 10px;
        @include sp{
            padding-right: 10/750*100vw
        }
    }

    &-pr20{
        padding-right: 20px;
        @include sp{
            padding-right: 20/750*100vw
        }
    }

    &-pr30{
        padding-right: 30px;
        @include sp{
            padding-right: 30/750*100vw
        }
    }

    &-pr40{
        padding-right: 40px;
        @include sp{
            padding-right: 40/750*100vw
        }
    }

    &-pr50{
        padding-right: 50px;
        @include sp{
            padding-right: 50/750*100vw
        }
    }



    &-pci{
        display:inline-block;
        @include sp{
            display:none;
        }
    }
    &-spi{
        display:none;
        @include sp{
            display:inline-block;
        }
    }
    &-pc{
        display:block;
        @include sp{
            display:none;
        }
    }
    &-sp{
        display:none;
        @include sp{
            display:block;
        }
    }


}
