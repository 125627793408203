@charset "utf-8";

//バナー
.m-banner {
  padding: 80px 0 50px;
  background: #F8F8F8;
  margin-bottom: 80px;
  @include sp{
    padding: 100/750*100vw 0 58/750*100vw;
    margin-bottom: 120/750*100vw;
  }


  &__inner{
    width: 1028px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include sp{
      width: 690/750*100vw;
    }

    &:before {
      content: "";
      display: block;
      width: 227px;
      height: 0;
      order: 1;
    }

    &:after {
      content: "";
      display: block;
      width: 227px;
      height: 0;
    }
  }

  img{
    width: 227px;
    margin-bottom: 30px;
    @include sp{
      width: 333/750*100vw;
      margin-bottom: 42/750*100vw;
    }
  }

}
