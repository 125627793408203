@charset "utf-8";

.p-404{
  margin-top: 100px;
  text-align: center;
  @include sp{
    margin: 100/750*100vw auto 0;
    width: 690/750*100vw;

  }

  &__ttl{
    font-size: 40px;
    @include sp{
      @include vw(64);
    }
  }

  &__sub{
    font-size: 30px;
    @include sp{
      @include vw(44);
    }
  }

  &__txt{
    font-size: 16px;
    margin-top: 80px;
    @include sp{
      margin-top: 100/750*100vw;
      @include vw(24);
      text-align: left;
    }
  }

  &__btn{
    display: block;
    width: 300px;
    height: 50px;
    background: #0d750f;
    color: #fff;
    margin: 80px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    @include sp{
      margin-top: 100/750*100vw;
      @include vw(24);
      width: 400/750*100vw;
      height: 100/750*100vw;
    }
  }

}
