@charset "utf-8";

.p-consulItem{
  margin-bottom: 25px;
  @include sp{
    margin-bottom: 40/750*100vw;
  }


  &__ttl{
    margin-bottom: 10px;
    padding-left: 20px;
    line-height: 30px;
    position: relative;
    font-size: 16px;
    @include sp{
      margin-bottom: 30/750*100vw;
      padding-left: 28/750*100vw;
      line-height: 40/750*100vw;
      @include vw(26);
    }
    // 緑線
    &::before{
      content: '';
      position: absolute;
      width: 5px;
      height: 30px;
      background-color: #0D750F;
      left: 0;
      @include sp{
        width: 6/750*100vw;
        height: 40/750*100vw;
      }
    }

    // 必須
    &--required{
      display: inline-block;
      position: relative;
      // 必須アイコン
      &::after{
        content: '必須';
        font-size: 10px;
        position: absolute;
        width: 30px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #CA2424;
        color: #fff;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 15px;
        @include sp{
          @include vw(22);
          width: 82/750*100vw;
          height: 32/750*100vw;
          margin-left: 30/750*100vw;
        }
      }
    }

    // 任意
    &--optional{
      display: inline-block;
      position: relative;
      // 任意アイコン
      &::after{
        content: '任意';
        font-size: 10px;
        position: absolute;
        width: 30px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #CA2424;
        background: #fff;
        color: #CA2424;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 15px;
        @include sp{
          @include vw(22);
          width: 82/750*100vw;
          height: 32/750*100vw;
          margin-left: 30/750*100vw;
        }
      }
    }
  }

  // 脚注
  &__txt{
    font-size: 14px;
    color: #BABABA;
    margin-top: 10px;
    @include sp{
      @include vw(24);
      margin-top: 16/750*100vw;
    }
  }

  // 入力欄（1行）
  &__input{
    width: 494px;
    font-size: 14px;
    height: 40px;
    @include sp{
      width: 100%;
      height: 100/750*100vw;
      @include vw(24);
    }

    &--age{
      width: 89px;
      margin-right: 15px;
      @include sp{
        width: 214/750*100vw;
        margin-left: 15/750*100vw;
      }
    }

    &--small{
      width: 200px;
      height: 40px;
      font-size: 14px;
      @include sp{
        width: 452/750*100vw;
        height: 100/750*100vw;
        @include vw(24);
      }
    }
  }



  // 入力欄（複数行）
  &__box{
    font-size: 14px;
    width: 100%;
    height: 190px;
    @include sp{
      height: 330/750*100vw;
      @include vw(24);
    }

    &--large{
      height: 272px;
      @include sp{
        height: 790/750*100vw;
      }
    }

    &--small{
      width: 100%;
      height: 55px;
      @include sp{
        width: 100%;
        height: 150/750*100vw;
      }
    }
  }

  // プルダウン
  &__select{
    width: 200px;
    height: 40px;
    @include sp{
      width: 452/750*100vw;
      height: 100/750*100vw;
      @include vw(24);
    }
  }

  &__age{
    font-size: 14px;
    @include sp{
      @include vw(26);
    }
  }

  &__inner{
    margin-bottom: 16px;
    @include sp{
      margin-bottom: 40/750*100vw;
    }
  }

  &__sub{
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
    @include sp{
      @include vw(26);
    }
  }

  //　自動入力ボタン
  &__btn{
    cursor: pointer;
    width: 121px;
    height: 40px;
    background: #7C7C7C;
    color: #fff;
    border: none;
    font-weight: bold;
    font-size: 14px;
    margin-left: 7px;
    @include sp{
      width: 214/750*100vw;
      height: 100/750*100vw;
      @include vw(28);
      margin-left: 10/750*100vw;
    }
  }


  &__check{
    display: flex;
    flex-wrap: wrap;
    @include sp{
      display: block;
    }
  }

  &__checkList{
    width:33%;
    margin-bottom: 15px;
    @include sp{
      width: 100%;
      margin-bottom: 40/750*100vw;
    }

    input{
      margin-right: 6px;
      @include sp{
        margin-right: 12/750*100vw;
      }
    }
  }

  &__checkTxt{
    font-size: 15px;
    @include sp{
      @include vw(26);
    }
  }


}
