@charset "utf-8";
.l-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background-color: #fff;
    z-index: 2000;
    @include sp{
        height: 18vw;
    }

    img {
        width: 100%;
        height: auto;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }


    &__wrap {
        display: flex;
        align-items: center;
        padding-right: 20px;

        @include sp {
            padding-right: 0;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;

        @include sp {
            padding: 34/750*100vw 30/750*100vw;
        }
    }

    &__logo {
        width: 274px;

        @include sp {
            width: 314/750*100vw;
        }
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__listItem {
        margin-left: 20px;
        font-size: 15px;
        font-weight: bold;

        &--mail {
            width: 31px;
            margin-left: 40px;

            @include sp {
                width: 44/750*100vw;
                margin-top: 0;
                margin-left: 25/750*100vw;
            }
        }

        &--search {
          cursor: pointer;
          width: 21px;
          margin-top: -7px;
          margin-left: 15px;

          @include sp {
              width: 40/750*100vw;
              margin-top: 0;
              margin-left: 35/750*100vw;
          }
      }





        &--lang {
            display: flex;
            align-items: center;
            margin-left: 15px;

            @include sp {
                margin-left: 35/750*100vw;
            }

            &::after {
                content: '';
                display: inline-block;
                width: 8px;
                height: 7px;
                margin-left: 10px;
                border-top: 2px solid #D90300;
                border-right: 2px solid #D90300;
                transform: rotate(135deg);

                @include sp {
                    width: 9/750*100vw;
                    height: 9/750*100vw;
                    margin-left: 20/750*100vw;
                }
            }

            &.is-active {
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__listItemInner {
        width: 20px;

        @include sp {
            width: 35/750*100vw;
        }
    }

    &__menu {
        width: 80px;
        height: 80px;
        margin-left: auto;
        background: url(#{$imgPath}common/header/menu.png) no-repeat center center/cover;
        cursor: pointer;
        transition: all 0.2s;
        @include sp {
            width: 18vw;
            height:  18vw;
        }
        &.is-active {
            background: url(#{$imgPath}common/header/menu_close.png) no-repeat center center/cover;
        }
    }

    &__sitemapBg{
      margin-top:80px;
      background: rgba(0, 0, 0, 0.8);
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      cursor: pointer;
      visibility: hidden;
      &.is-active {
        visibility: visible;
      }
      @include sp {
        margin-top:18vw;
      }

    }
    &__sitemap {
        overflow-y: scroll;
        height: 95vh;
        z-index: 200;
        display:none;
        position: relative;
        padding: 40px 0 32px 0;
        color: #fff;
        background-color: #053306;
        width: 675px;
        margin-left: auto;

        @include sp {
            height: 100vh;
            width: 683/750*100vw;
            padding: 48/750*100vw 0 120/750*100vw;
            margin-top: 2/750*100vw;
        }
    }

    &__sitemapHeading {
        width: 90px;

        @include sp {
            width: 195/750*100vw;
        }
    }

    &__sitemapContent {
        width: 508px;
        margin: 0 auto;

        @include sp {
            width: 100%;
            margin-top: 40/750*100vw;
            padding: 0 30/750*100vw;
        }
    }

    &__sitemapList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 16px;
        @include sp {
            display: block;
            margin-top: 40/750*100vw;
        }
    }

    &__sitemapListItem {
      @include pc{
        width: 240px;
        margin-bottom: 76px;
      }
        &:not(:first-child) {
            //margin-left: 40px;

            @include sp {
                margin-top: 50/750*100vw;
                margin-left: 0;
            }
        }

        a{
          color: #fff;
        }

        ul {
            margin-top: 15px;
            @include sp {
                margin-top: 40/750*100vw;
            }

            li {
                font-size: 14px;

                @include sp {
                    @include vw(26);
                    padding-bottom: 20/750*100vw;
                }

                &:not(:first-child) {
                    margin-top: 10px;
                }

                a {
                    color: #fff;
                }
            }
        }
    }

    &__accordion1{
      @include sp {
        display: none;
      }
  }

    &__accordion2{
        margin-left: 20px;
        display: none;
    }

    &__sitemapTtlText {
        padding-bottom: 5px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.05em;
        border-bottom: 1px solid #fff;
        width: 240px;
        @include sp {
            border-color: #AFAFAF;
            width: 100%;
            @include vw(40);
        }
        a{
          color: #fff;
        }

    }
    &__sitemapTtl {
      position: relative;
      width: 240px;
        @include sp {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    &__sitemapTxt{
      position: relative;
      padding-right: 20px;
      padding-bottom: 10px;
      font-size: 12px;
      border-bottom: 1px solid #415041;
    }

    &__accordionBtn1 {
      position: absolute;
      right: 0;
      top: 12px;
      width: 16px;
        @include sp {
            position: absolute;
            right: 0.5em;
            top: 0.5em;
            width: 36/750*100vw;
            height: 36/750*100vw;
        }
    }

    &__accordionBtn2 {
      position: absolute;
      right: 0;
      top: 12px;
      width: 16px;
        @include sp {
            position: absolute;
            right: 0.5em;
            top: 0em;
            width: 36/750*100vw;
            height: 36/750*100vw;
        }
    }

    &__accordionBtnIcon {
      cursor: pointer;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      right: 0;
      background: url(#{$imgPath}common/plus_icon.png) no-repeat center center;
      transform: translateY(-50%);

      &.is-active {
          background: url(#{$imgPath}common/minus_icon.png) no-repeat center center;
      }
        @include sp {
            width: 36/750*100vw;
            height: 36/750*100vw;
        }
    }


    &__bottomBlock {
        display: flex;
        align-items: flex-end;
        height: 70px;
        @include sp{
            height: auto;
            margin-top: 80/750*100vw;
        }
    }
    &__bottomBlockWrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1090px;
        margin: 0 auto;
        @include sp{
            display:block;
            padding: 0 30/750*100vw 64/750*100vw;
        }
    }

    &__contactList {
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid #AFAFAF;
        @include sp{
            margin: 0 auto;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: none;
        }
    }

    &__contactListItem {
        padding: 0 30px;
        font-size: 14px;
        @include sp{
            width: 48%;
            @include vw(24);
            padding: 0 0 16/750*100vw;
            margin-bottom: 35/750*100vw;
            border-bottom: 1px solid #afafaf;
        }
        @include pc{
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &:not(:first-child) {
              position: relative;
              &::before{
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 10px;
                width: 1px;
                background-color: #707070;
              }

            }

        }
        a{
          color: #fff;
        }

    }


    &__shareItem {
        @include sp{
            width: 52/750*100vw;
            margin: 0 40/750*100vw 0 auto;
        }
    }
}



//検索
.modal{
    display: none;
    position: fixed;
    z-index: 2000;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.7);

    &__search{
      display: flex;
    }

    &__input{
      font-size: 20px;
      width: 592px;
      height: 80px;
      background-color: #fff;
      @include sp{
        width: 512/750*100vw;
        height: 80/750*100vw;
        @include vw(28);
      }
  }

  &__submit{
      display: block;
      border: none;
      padding: 0;
      cursor: pointer;
      width: 80px;
      background: url(#{$imgPath}common/header/modal_search.png) no-repeat center center;
      background-size: contain;
      @include sp{
          width: 80/750*100vw;
      }
  }

    &__body {
      padding: 79px 0;
      @include sp{
        padding: 79/750*100vw 0;
      }
  }

    &__inner{
        width: 682px;
        margin: 0 auto;
        @include sp{
          width: 600/750*100vw;
        }
    }

    //×印
    &__content {
        background-color: #fff;
        margin: 153px auto;
        width: 850px;
        position: relative;
        @include sp{
          width: 690/750*100vw;
          margin: 164/750*100vw auto;;
        }
    }

    &__close{
        width: 46px;
        position: absolute;
        color: #fff;
        top: -50px;
        right: 0;
        @include sp{
            width: 46/750*100vw;
            top: -50/750*100vw;
        }
        &:hover {
          cursor: pointer;
        }
      }

  }
