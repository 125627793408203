@charset "utf-8";

.p-contact {
  width: 1028px;
  margin: 80px auto 155px;
  @include sp{
    width: 690/750*100vw;
    margin: 80/750*100vw auto 160/750*100vw;;
  }
  ul{
    list-style: none;
    padding: 0;
  }

  &__ttl{
    position: relative;
    color: #0D750F;
    font-size: 32px;
    border-bottom: solid 3px #0D750F;
    padding: 0 0 17px 29px;
    margin-bottom: 26px;
    @include sp{
      @include vw(42);
      padding: 0 0 25/750*100vw 40/750*100vw;
      margin-bottom: 40/750*100vw;
    }
    //　赤丸
    &::before{
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: #D90300;
      border-radius: 50%;
      left: 0;
      top: 20px;
      @include sp{
        top: 25/750*100vw;
        left: 7/750*100vw;
        width: 20/750*100vw;
        height: 20/750*100vw;
      }
    }
  }

  &__wrap{
    &:not(:first-of-type){
      margin-top: 80px;
      @include sp{
        margin-top: 80/750*100vw;
      }
    }
  }

  &__sub{
    position: relative;
    font-size: 20px;
    padding-left: 33px;
    line-height: 52px;
    margin-bottom: 24px;
    @include sp{
      @include vw(32);
      padding-left: 40/750*100vw;
      margin-bottom: 40/750*100vw;
      line-height: 80/750*100vw;
    }
     // 緑線
    &::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 52px;
      background-color: #0D750F;
      left: 0;
      @include sp{
        width: 10/750*100vw;
        height: 80/750*100vw;
      }
    }
  }

  &__listWrap{
    margin-bottom: 74px;
    @include sp{
      margin-bottom: 80/750*100vw;
    }
  }


  &__list{
    font-size: 16px;
    margin-bottom: 10px;
    position: relative;
    @include sp{
      @include vw(28);
      margin-bottom: 20/750*100vw;
    }

    &--dot{
      padding-left: 1.2em;
      // 黒丸
      &::before{
        position: absolute;
        content: '●';
        left: 0;
      }
    }
  }

  &__link{
    text-decoration: underline;
  }



  //確認画面へ
  &__btn{
    display: block;
    width: 396px;
    height: 78px;
    border: solid 1px #9F9F9F;
    margin: 50px auto 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp{
      width: 452/750*100vw;
      height: 100/750*100vw;
      margin: 80/750*100vw auto 0;
      @include vw(26);
    }
  }

  .entry-header{
    display: none;
  }
  input[type=submit]{
    display: block;
    width: 396px;
    height: 78px;
    border: solid 1px #9F9F9F;
    margin: 50px auto 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp{
      width: 452/750*100vw;
      height: 100/750*100vw;
      margin: 80/750*100vw auto 0;
      @include vw(26);
    }
  }
}
