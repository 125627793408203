@charset "utf-8";

.m-icon {
  //NEWアイコン
  &--new{
    position: relative;
    &::after{
      position: absolute;
      content: '';
      background: url(../img/article/icon_new.png) no-repeat center;
      background-size: contain;
      display: inline-block;
      width: 50px;
      height: 26px;
      top: 50%;
      margin-left: 20px;
      transform: translateY(-50%);
      @include sp{
        width: 70/750*100vw;
        height: 40/750*100vw;
        margin-left: 20/750*100vw;;
      }
    }
  }
  //NEWアイコン 他のアイコンと併用
  &--new2{
    a{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_new.png) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 50px;
        height: 26px;
        top: 50%;
        margin-left: 60px;
        transform: translateY(-50%);
        @include sp{
          width: 70/750*100vw;
          height: 40/750*100vw;
          margin-left: 80/750*100vw;;
        }
      }
    }
  }
  //更新アイコン
  &--update{
    position: relative;
    &::after{
      position: absolute;
      content: '';
      background: url(../img/article/icon_update.png) no-repeat center;
      background-size: contain;
      display: inline-block;
      width: 50px;
      height:26px;
      top: 50%;
      margin-left: 20px;
      transform: translateY(-50%);
      @include sp{
        width: 70/750*100vw;
        height: 40/750*100vw;
        margin-left: 20/750*100vw;;
      }
    }
  }
  //更新アイコン　他のアイコンと併用
  &--update2{
    a{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_update.png) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 50px;
        height:26px;
        top: 50%;
        margin-left: 60px;
        transform: translateY(-50%);
        @include sp{
          width: 70/750*100vw;
          height: 40/750*100vw;
          margin-left: 80/750*100vw;;
        }
      }
    }
    }
}
