@charset "utf-8";

.p-article {
  width: 1028px;
  margin: 40px auto 155px;
  @include sp{
    width: 690/750*100vw;
    margin: 40/750*100vw auto 249/750*100vw;;
  }


  h2{
    position: relative;
    color: #0D750F;
    font-size: 32px;
    border-bottom: solid 3px #0D750F;
    padding: 0 0 17px 29px;
    margin-bottom: 40px;
    @include sp{
      @include vw(42);
      padding: 0 0 25/750*100vw 40/750*100vw;
      margin-bottom: 60/750*100vw;
    }
    //　赤丸
    &::before{
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: #D90300;
      border-radius: 50%;
      left: 0;
      top: 20px;
      @include sp{
        top: 25/750*100vw;
        left: 7/750*100vw;
        width: 20/750*100vw;
        height: 20/750*100vw;
      }
    }
  }


  h3{
    position: relative;
    font-size: 20px;
    padding-left: 33px;
    line-height: 52px;
    margin: 0 0 24px;
    @include sp{
      @include vw(32);
      padding-left: 40/750*100vw;
      margin: 0 0 40/750*100vw;
      line-height: 80/750*100vw;
    }
     // 緑線
    &::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 52px;
      background-color: #0D750F;
      left: 0;
      @include sp{
        width: 10/750*100vw;
        height: 80/750*100vw;
      }
    }
  }

  //本文
  p{
    font-size: 16px;
    line-height: 2.0;
    margin-bottom: 10px;
    @include sp{
      @include vw(28);
      margin-bottom: 20/750*100vw;
    }
  }

  //表
  &__table{
    @include sp{
      overflow-x: scroll;
    }
  }
  .wp-block-table{
    margin: 0;
    @include sp{
      overflow-x: scroll;
    }
  }
  table{
    width: 1028px;
    margin: 0;
    @include sp{
      width: 1651/750*100vw;
      overflow-x: scroll;
    }
    th{
      border: solid 1px #7C7C7C;
      width: 178px;
      height: 60px;
      background: #EBEBEB;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 400;
      padding: 0 10px;
      @include sp{
        width: 286/750*100vw;
        height: 96/750*100vw;
        @include vw(26);
        padding: 0 20/750*100vw;
      }
    }
    td{
      border: solid 1px #7C7C7C;
      height: 60px;
      vertical-align: middle;
      padding: 0;
      font-size: 15px;
      padding: 0 10px;
      @include sp{
        height: 96/750*100vw;
        padding: 0 20/750*100vw;
        @include vw(26);
      }
    }
  }

  &__data{
    padding: 0;
  }

  &__dataList{
    list-style: none;
    font-size: 16px;
    text-decoration: underline;
    padding-left: 20px;
    position: relative;
    margin-top: 25px;
    @include sp{
      padding-left: 39/750*100vw;
      @include vw(26);
      margin-top: 57/750*100vw;
    }

    //緑矢印
    &::before{
      position: absolute;
      content: '';
      background: url(../img/article/arrow.png) no-repeat center;
      background-size: contain;
      display: inline-block;
      width: 8px;
      height: 16px;
      right: 100%;;
      top: 50%;
      transform: translateY(-50%);
      @include sp{
        width: 14/750*100vw;
        height: 29/750*100vw;
      }
    }

    //PDFアイコン
    &--pdf{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_pdf.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 26px;
        height: 30px;
        top: 50%;
        margin-left: 20px;
        transform: translateY(-50%);
        @include sp{
          width: 48/750*100vw;
          height: 55/750*100vw;
          margin-left: 20/750*100vw;;
        }
      }
    }

    //DOCアイコン
    &--doc{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_doc.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 26px;
        height: 30px;
        top: 50%;
        margin-left: 20px;
        transform: translateY(-50%);
        @include sp{
          width: 48/750*100vw;
          height: 55/750*100vw;
          margin-left: 20/750*100vw;;
        }
      }
    }

    //XLSアイコン
    &--xls{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_xls.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 26px;
        height: 30px;
        top: 50%;
        margin-left: 20px;
        transform: translateY(-50%);
        @include sp{
          width: 48/750*100vw;
          height: 55/750*100vw;
          margin-left: 20/750*100vw;;
        }
      }
    }

    //外部リンクアイコン
    &--link{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_link.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 24px;
        height: 24px;
        top: 50%;
        margin-left: 20px;
        transform: translateY(-50%);
        @include sp{
          width: 42/750*100vw;
          height: 42/750*100vw;
          margin-left: 20/750*100vw;;
        }
      }
    }
    //動画アイコン
    &--movie{
      position: relative;
      &::after{
        position: absolute;
        content: '';
        background: url(../img/article/icon_movie.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        width: 24px;
        height: 24px;
        top: 50%;
        margin-left: 20px;
        transform: translateY(-50%);
        @include sp{
          width: 42/750*100vw;
          height: 42/750*100vw;
          margin-left: 20/750*100vw;;
        }
      }
    }


  }

  //余白
  .u{
    //margin
      &-m0{
          margin: 0;
      }

      //margin-top
      &-mt0{
        margin-top: 0;
      }

      &-mt10{
          margin-top: 10px;
          @include sp{
              margin-top: 10/750*100vw
          }
      }

      &-mt20{
          margin-top: 20px;
          @include sp{
              margin-top: 20/750*100vw
          }
      }

      &-mt30{
          margin-top: 30px;
          @include sp{
              margin-top: 30/750*100vw
          }
      }

      &-mt40{
          margin-top: 40px;
          @include sp{
              margin-top: 40/750*100vw
          }
      }

      &-mt50{
          margin-top: 50px;
          @include sp{
              margin-top: 50/750*100vw
          }
      }

      //margin-bottom
      &-mb0{
        margin-bottom: 0;
      }

      &-mb10{
          margin-bottom: 10px;
          @include sp{
              margin-bottom: 10/750*100vw
          }
      }

      &-mb20{
          margin-bottom: 20px;
          @include sp{
              margin-bottom: 20/750*100vw
          }
      }

      &-mb30{
          margin-bottom: 30px;
          @include sp{
              margin-bottom: 30/750*100vw
          }
      }

      &-mb40{
          margin-bottom: 40px;
          @include sp{
              margin-bottom: 40/750*100vw
          }
      }

      &-mb50{
          margin-bottom: 50px;
          @include sp{
              margin-bottom: 50/750*100vw
          }
      }

      //margin-left
      &-ml0{
        margin-left: 0;
      }

      &-ml10{
          margin-left: 10px;
          @include sp{
              margin-left: 10/750*100vw
          }
      }

      &-ml20{
          margin-left: 20px;
          @include sp{
              margin-left: 20/750*100vw
          }
      }

      &-ml30{
          margin-left: 30px;
          @include sp{
              margin-left: 30/750*100vw
          }
      }

      &-ml40{
          margin-left: 40px;
          @include sp{
              margin-left: 40/750*100vw
          }
      }

      &-ml50{
          margin-left: 50px;
          @include sp{
              margin-left: 50/750*100vw
          }
      }

      //margin-right
      &-mr0{
        margin-right: 0;
      }

      &-mr10{
          margin-right: 10px;
          @include sp{
              margin-right: 10/750*100vw
          }
      }

      &-mr20{
          margin-right: 20px;
          @include sp{
              margin-right: 20/750*100vw
          }
      }

      &-mr30{
          margin-right: 30px;
          @include sp{
              margin-right: 30/750*100vw
          }
      }

      &-mr40{
          margin-right: 40px;
          @include sp{
              margin-right: 40/750*100vw
          }
      }

      &-mr50{
          margin-right: 50px;
          @include sp{
              margin-right: 50/750*100vw
          }
      }

      // padding
      &-p0{
        padding: 0;
      }

      //padding-top
      &-pt0{
        padding-top: 0;
      }

      &-pt10{
          padding-top: 10px;
          @include sp{
              padding-top: 10/750*100vw
          }
      }

      &-pt20{
          padding-top: 20px;
          @include sp{
              padding-top: 20/750*100vw
          }
      }

      &-pt30{
          padding-top: 30px;
          @include sp{
              padding-top: 30/750*100vw
          }
      }

      &-pt40{
          padding-top: 40px;
          @include sp{
              padding-top: 40/750*100vw
          }
      }

      &-pt50{
          padding-top: 50px;
          @include sp{
              padding-top: 50/750*100vw
          }
      }

      //padding-bottom
      &-pb0{
        padding-bottom: 0;
      }

      &-pb10{
          padding-bottom: 10px;
          @include sp{
              padding-bottom: 10/750*100vw
          }
      }

      &-pb20{
          padding-bottom: 20px;
          @include sp{
              padding-bottom: 20/750*100vw
          }
      }

      &-pb30{
          padding-bottom: 30px;
          @include sp{
              padding-bottom: 30/750*100vw
          }
      }

      &-pb40{
          padding-bottom: 40px;
          @include sp{
              padding-bottom: 40/750*100vw
          }
      }

      &-pb50{
          padding-bottom: 50px;
          @include sp{
              padding-bottom: 50/750*100vw
          }
      }

      //padding-left
      &-pl0{
        padding-left: 0;
      }

      &-pl10{
          padding-left: 10px;
          @include sp{
              padding-left: 10/750*100vw
          }
      }

      &-pl20{
          padding-left: 20px;
          @include sp{
              padding-left: 20/750*100vw
          }
      }

      &-pl30{
          padding-left: 30px;
          @include sp{
              padding-left: 30/750*100vw
          }
      }

      &-pl40{
          padding-left: 40px;
          @include sp{
              padding-left: 40/750*100vw
          }
      }

      &-pl50{
          padding-left: 50px;
          @include sp{
              padding-left: 50/750*100vw
          }
      }

      //padding-right
      &-pr0{
        padding-right: 0;
      }

      &-pr10{
          padding-right: 10px;
          @include sp{
              padding-right: 10/750*100vw
          }
      }

      &-pr20{
          padding-right: 20px;
          @include sp{
              padding-right: 20/750*100vw
          }
      }

      &-pr30{
          padding-right: 30px;
          @include sp{
              padding-right: 30/750*100vw
          }
      }

      &-pr40{
          padding-right: 40px;
          @include sp{
              padding-right: 40/750*100vw
          }
      }

      &-pr50{
          padding-right: 50px;
          @include sp{
              padding-right: 50/750*100vw
          }
      }
    }

  //ページ内リンク調整
  .m-pagelink {
    position: relative;
    z-index: -1;
    padding-top: 90px;
    margin-top: -90px;
    @include sp{
      padding-top: 20vw;
      margin-top: -20vw;
    }
  }

  // WP調整用
  .dataTables_filter{
    display: none;
  }

  .tablepress tfoot th, .tablepress thead th{
    background-color: #0d750f;
    color: #fff;
    &:hover{
      background: #053306;
    }
  }

  .paginate_button:after, .paginate_button:before{
    color: #0d750f;

  }
  .paginate_button:hover:after,.paginate_button:hover:before{
    color:#053306;
  }

  a{
    text-decoration: underline;
  }


}

