@charset "utf-8";

.p-topInsurance {
  background: #F8F8F8;
  padding: 100px 0;
  @include sp{
    padding: 50/750*100vw 0;
    background: url('../img/top/insurance/insurance_bg--sp.png') no-repeat center top;
    background-size: contain;
  }
  &__wrap{
    width: 1190px;
    margin: 0 auto;
    background: #0D750F  url('../img/top/insurance/insurance_bg.png') no-repeat center top;
    @include sp{
      width: 690/750*100vw;
      background: #0D750F  url('../img/top/insurance/bnr--sp.png') no-repeat center ;
      background-size: contain;
      height: 340/750*100vw;
    }
  }
  &__bnr{
    width: 1030px;
    margin: 0 auto;
    padding: 65px 0;
    position: relative;
    @include sp{
      width: 100%;
      padding: 0;
    }
  }
  &__ttl{
    position: absolute;
    top: 30%;
    left: 55px;
    @include sp{
      top:0;
      margin-top: 30/750*100vw;
      left: 50/750*100vw;
      .m-heading__text--large{
        @include vw(52);
      }
    }
  }
  &__txt{
    margin-top: 10px;
    font-size: 16px;
    @include sp{
      margin-top: 15/750*100vw;
      @include vw(26);
    }
  }
}
