@charset "utf-8";

.p-topFooter {
  background: url('../img/top/footer/footer_bg.png') no-repeat center bottom;
  width: 100%;
  height: 300px;
  background-size: cover;
  @include sp{
    background: url('../img/top/footer/footer_bg--sp.png') no-repeat center bottom;
    background-size: contain;
    height: 380/750*100vw;
  }
}
