@charset "utf-8";

.p-topAbout {
  background: #F8F8F8;
  padding: 0 0 105px 0;
  @include sp{
    padding: 100/750*100vw 0;
    background: #FFF;
  }
  &__wrap{
    width: 1030px;
    margin: 0 auto;
    @include sp{
      width: 690/750*100vw;
    }
  }
  &__txt{
    font-size: 16px;
    line-height: 2.5;
    margin-top: 20px;
    @include sp{
      @include vw(28);
      line-height: 1.8;
      margin-top: 24/750*100vw;
    }
  }
  &__link{
    display: block;
      margin-top: 45px;
      @include sp{
        margin-top: 60/750*100vw;
      }
  }
}
