@charset "utf-8";
.l-main{
    min-width: 1140px;
    min-height: 100vh;
    padding-top: 106px;
    overflow: hidden;
    @include sp{
        padding-top: 18vw;
        min-width: 100%;
    }
}


html.is-fixed{
    overflow: hidden;
}
