@charset "utf-8";


// ピックアップ　スライド

.m-slide{
  &__ttlWrap{
    width: 1028px;
    margin: 0 auto;
    @include sp{
      width: 690/750*100vw;
    }
  }

  ul, li{
    list-style: none;
  }



  // slider

  &__slide{
    position: relative;
    width: 1206px;
    margin: 0 auto;
    @include sp{
      width: 690/750*100vw;
      border-top: solid 1px #9F9F9F;
    }
  }

  &__slideBox{
    width: 1028px;
    margin: 10px auto 130px;
    padding: 32px 0 56px;
    border-top: solid 1px #9F9F9F;
    @include sp{
      width: 500/750*100vw;
      margin: 0 auto 120/750*100vw;
      padding: 40/750*100vw 0 80/750*100vw;
      border: none;
    }

  }


  &__item{
    width: 316px;
    border-bottom: solid 1px #9F9F9F;
    @include sp{
      width: 100%
    }
    img{
      width: 100%;
    }
  }

  &__itemTtl{
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    @include sp{
      @include vw(30);
      margin-top: 38/750*100vw;
    }
  }

  &__itemTxt{
    font-size: 16px;
    margin: 17px 0 36px;
    @include sp{
      @include vw(28);
      margin: 17/750*100vw 0 41/750*100vw;
    }
  }

  &__itemMore{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    display: inline-block;
    position: relative;
    @include sp{
      @include vw(26);
      margin-bottom: 10/750*100vw;
      color: #D90300;
    }
    &::after{
      position: absolute;
      content: url(../img/common/link_arrow.png);
      width: 32px;
      height: 5px;
      top: -5px;
      left: 110%;
      @include sp{
        width: 51/750*100vw;
        height: 7/750*100vw;
        top: -7/750*100vw;
      }
    }
  }

  .swiper-wrapper{
    padding: 0;
  }

  //矢印カスタマイズ
  .swiper-button-next{
    background-image: url(../img/common/arrow_next.png);
    background-size: contain;
    @include sp{
      width: 38/750*100vw;
    }
  }
  .swiper-button-prev {
    background-image: url(../img/common/arrow_prev.png);
    background-size: contain;
    @include sp{
      width: 38/750*100vw;
    }
  }

  //ページネーションカスタマイズ
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #fff;
    border: solid 2px #9F9F9F;
    margin: 0 15px !important;
    @include sp{
      width: 18/750*100vw;
      height: 18/750*100vw;
      margin: 0 20/750*100vw !important;
    }
  }
  .swiper-pagination-bullet-active {
    background: #D90300;
    border: none;
  }

}
