@charset "utf-8";

//パンくず (pcのみ)


.m-list {
  &>span{
    width: 1206px;
    margin: 10px auto 0;
    display: flex;
    justify-content: flex-end;
    span{
      display: flex;
      &:not(:first-child) {
        padding-left: 30px;
        position: relative;

        &::before {
          position: absolute;
          content: '＞';
          font-size: 12px;
          width: 16px;
          height: 16px;
          left: 8px;
          top: 40%;
          transform: translateY(-50%);
          color: #9F9F9F;
        }
      }
      a {
        color: #9F9F9F;
        font-size: 12px;
      }
      .breadcrumb_last{
        color: #9F9F9F;
        font-size: 12px;
      }

    }
  }
}
