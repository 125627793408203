@charset "utf-8";

.p-topCalendar {
  width: 1015px;
  margin: 0 auto 100px;
  @include sp {
    width: 100%;
    margin: 0 auto 100/750*100vw;
    padding:0 30/750*100vw;
  }

  &__heading {
    @include sp {
      padding-bottom: 20/750*100vw;
      border-bottom: 1px solid #9F9F9F;
    }
  }
  &__flex{
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    @include sp{
      display: block;
      margin-top: 0;
    }
  }
  &__calendar{
    width: 1000px;
    padding: 24px 0;
    @include pc {
      border-top: solid 1px #9F9F9F;
      border-bottom: solid 1px #9F9F9F;
    }
    @include sp{
      width: 100%;
      padding: 38/750*100vw 0 0;
    }
  }
  &__data{
    display: flex;
    justify-content: space-between;
  }
  &__month{
    font-size: 20px;
    font-weight: 800;
    color: #707070;
    @include sp{
      @include vw(36);
    }
  }
  &__arrow{
    width: 70px;
    justify-content: space-between;
    display: flex;
    @include sp{
      width: 114/750*100vw;
    }
    img{


      width: 31px;
      @include sp{
        width: 51/750*100vw;
      }
    }
  }
  &__calendarImg{
    margin-top: 20px;
    @include sp{
      margin-top: 30/750*100vw;
    }
  }
  &__list{
    border-top: solid 1px #9F9F9F;
    border-bottom: solid 1px #9F9F9F;
    margin: 0;
    padding: 0;
    @include sp{
      border: solid 1px #9F9F9F;
      width:100%;
      // height: 360/750*100vw;
      // overflow-y: scroll;
    }
  }

  &__btn{
    margin: 10/750*100vw 0 0 auto;
    width: 50/750*100vw;
    height: 50/750*100vw;
    cursor: pointer;
    background: url(#{$imgPath}common/common_plus.png) no-repeat center center;
    background-size: contain;
    &.is-active {
      background: url(#{$imgPath}common/common_minus.png) no-repeat center center;
      background-size: contain;
    }

  }

  .js-calendar-accordion{
    @include sp{
      height: 700/750*100vw;
      overflow: hidden;
      padding-right: 1px;
    }
  }

  &__listItem{
    display: flex;
    &:nth-child(odd){
        background: #F8F8F8;
    }
  }
  &__txt{
    width: 513px;
    padding: 15px 0;
    display: flex;
    align-items: baseline;
    @include sp{
      width: 100%;
      padding: 25/750*100vw 0;
    }
    p{
      font-size: 16px;
      padding: 0 20px;
      @include sp{
        @include vw(26);
        padding: 0 25/750*100vw;
      }
    }
  }
  &__date{
    font-size: 14px;
    color: #1A1A1A;
    padding: 0 20px;
    vertical-align: baseline;
    display: inline-block;
    border-right: solid 2px #DEDEDE;
    @include sp{
      padding: 0 25/750*100vw;
      @include vw(26);
    }
  }
  .fc-content{
    .fc-title{
      font-size: 10px;
    }
    .fc-time{
      font-size: 10px;
      display: none;
    }

  }
  .fc-event-container{
    .fc-day-grid-event{
      // pointer-events: none;
      background: none !important;
      border: none !important;
      color: #000 !important;
    }
  }

}
