@charset "utf-8";

.p-topMountains {
  background-color: #F8F8F8;
  &__box{
    width: 1366px;
    margin: 100px auto 0;
    padding-top: 80px;
    background-color: #F8F8F8;
    @include sp {
      width: 100%;
      margin: 120/750*100vw auto 0;
      padding-top: 0;
      background-color: #fff;
    }
  }

  &__heading {
    padding-left: 80px;
    @include sp {
      padding: 120/750*100vw 30/750*100vw 260/750*100vw;
      background-color: #F8F8F8;
    }
  }

  &__wrap {
    position: relative;
    width: 100%;
    height: 603px;
    background: url(#{$imgPath}top/mountains/bg_img.jpg) no-repeat left bottom;
    z-index: 1;
    margin-top: 10px;
    @include sp {
      height: auto;
      padding: 0;
      margin: 0;
      background: none;
    }
  }

  &__content {
    &::before {
      content: '';
      position: absolute;
      left: 80px;
      width: 583px;
      height: 390px;
      background: url(#{$imgPath}top/mountains/img.jpg) no-repeat center center/cover;
      z-index: 2;

      @include sp {
        width: 690/750*100vw;
        height: 460/750*100vw;
        top: -250/750*100vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__inner {
    position: absolute;
    right: 0;
    margin-top: 50px;
    margin-right: 80px;
    width: 760px;
    padding: 110px 0 132px 237px;
    background-color: #fff;
    z-index: 1;
    @include sp {
      position: relative;
      width: 100%;
      margin: 0;
      padding: 270/750*100vw 70/750*100vw 100/750*100vw;
    }
  }

  &__ttl {
    font-size: 32px;
    font-weight: 900;
    @include sp {
      @include vw(40);
    }
  }

  &__lead {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #555555;
    @include sp {
      @include vw(28);
      margin-top: 15/750*100vw;
      line-height: 1.42857143;
    }
  }

  &__link {
    margin-top: 40px;
    @include sp {
      margin-top: 40/750*100vw;
    }
  }
}
