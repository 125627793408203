@charset "utf-8";

//ページ内リンク調整
.m-pagelink {
  position: relative;
  z-index: -1;
  padding-top: 90px;
  margin-top: -90px;
  @include sp{
    padding-top: 20vw;
    margin-top: -20vw;
  }
}
