@charset "utf-8";

.m-heading {
    // 英語見出し
  &__en {
    img {
      width: auto;
      height: 100%;
    }

    //NEWS CALENDER PICKUP
    &--md {
      width: auto;
      height: 12px;
      @include sp {
        height: 25/750*100vw;
      }
    }

    //MOUNTAINS SPORTSCLIMBING INSURANCE ABOUT
    &--large {
      width: auto;
      height: 15px;
      @include sp {
        height: 25/750*100vw;
      }
    }

  }

  // 日本語見出し
  &__text {
    font-weight: 900;

    &--md {
      margin-top: 15px;
      font-size: 32px;
      @include sp {
        @include vw(52);
      }
    }

    &--large {
      margin-top: 10px;
      font-size: 60px;
      @include sp {
        @include vw(90);
        margin-top: 40/750*100vw;
        line-height: 1.2;
      }
    }
  }
}
