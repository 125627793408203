@charset "utf-8";

.p-topKv {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  @include sp{
    flex-wrap: wrap;
    width: 690/750*100vw;
  }
  &__slide{
    width: 840px;
    @include sp{
      width: 100%;
      order: 1;
    }
  }
}

.p-topKvHeading{
  width: 306px;
  margin-top: 50px;
  @include sp{
    width: 100%;
    order: 2;
    margin-top: 30/750*100vw;
  }
  &__text{
    margin-top: 50px;
    font-size: 16px;
    line-height: 2;
    @include sp{
      margin-top: 25/750*100vw;
      @include vw(26);
    }
  }
}

//　スライダー
.p-topKvMain{
  @include sp{
    // height: 620/750*100vw;
    height: auto;
    width: 100%;
    overflow: hidden;
  }

  &__item{
    img{
      width: 100%;
      @include sp{
        height: auto;
        width: 100%;
      }
    }
  }
}

// サムネイル
.p-topKvThumb{
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  @include sp{
    margin-top: 10/750*100vw;
  }
  &__item{
    position: relative;
    width: 207px!important;
    padding-top: 4px;
    background: #EFEDE7;
    opacity: 0.6;
    @include sp{
      width: 170/750*100vw!important;
    }
    img{
      width: 100%;
    }
    &:not(:first-child){
      margin-left: 4px;
      @include sp{
        margin-left: 5/750*100vw;
      }
    }
    &::after{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 4px;
      width: 0%;
      background: #D90300;
      }
    &.is-active{
      opacity: 1;
      &::after{
        width: 100%;
        transition: all 2.1s linear;
      }
    }

  }
}

